import axios from 'axios';
import allowedUnauthUrls from '@/helpers/allowedUnauthUrls';
import apiClient from './apiClient';
import {USER_TOKEN_KEY} from '@/helpers/localStorageLabels';

export default class BaseAPIClient {
  instance = null;

  constructor(unauthorizedHandler) {
    this.createInstance();
    this.setInterceptors(unauthorizedHandler);
    this.initToken();
  }

  createInstance() {
    this.instance = axios.create({
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 1000 * 60 * 60 * 24,
    });
  }

  async handleUnauthorizedError(error) {
    const isNeedToKickOut = (error.response?.config?.url && allowedUnauthUrls.every(url => !error.response?.config?.url.includes(url)));
    if (error.response?.status === 401 && isNeedToKickOut) {
      try {
        const userToken = sessionStorage.getItem(USER_TOKEN_KEY);
        await apiClient.logout(userToken);
      } finally {
        localStorage.clear();
        sessionStorage.clear();
        window.location.assign('/login');
      }
    }
    return Promise.reject(error);
  }

  setInterceptors() {
    this.instance.interceptors.response.use(undefined, (error) => {
      return this.handleUnauthorizedError(error);
    });
    this.instance.interceptors.request.use((config) => {
      if (!this.token) {
        config.headers.common['x-access-token'] = sessionStorage.getItem(USER_TOKEN_KEY);
      } else {
        config.headers.common['x-access-token'] = this.token;
      }
      return config;
    });
  }

  initToken() {
    const token = sessionStorage.getItem(USER_TOKEN_KEY);

    if (token) {
      this.setToken(token);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  setToken(token) {
    BaseAPIClient.prototype.token = token;
  }
}
