<template>
  <v-app @mousemove='mousemove'>
    <TopNavbar />
    <v-main>
      <v-container fluid>
        <RotateAlert/>
        <router-view />
      </v-container>
    </v-main>
    <ConfirmModal 
      title='Warning: multiple users from one browser'
      confirmText='Close'
      cancelText=''
      text='Two different users logging in from the same browser. The users will now be logged out.'
      :dialog='displayModal'
      @closeConfirmModal='handleCloseModal'
    />
    <AppFooter />
  </v-app>
</template>

<script>
import TopNavbar from './components/TopNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import ConfirmModal from './components/common/ConfirmModal.vue';
import RotateAlert from '@/RotateAlert.vue';
import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import {debounce} from 'lodash/function';
import {USER_NAME_KEY, USER_TOKEN_KEY} from '@/helpers/localStorageLabels';
import {rolesObject} from '@/helpers/roles';

export default {
  components: {
    TopNavbar,
    AppFooter,
    ConfirmModal,
    RotateAlert,
  },

  data() {
    return {
      logoutInterval: null,
      displayModal: false,
    };
  },

  computed: {
    ...mapGetters(['isAuthenticated']),
  },

  watch: {
    isAuthenticated(newValue) {
      if (newValue) {
        this.refreshLogoutTimeout();
      }
    },
  },

  methods: {
    ...mapActions({ logout: AUTH_LOGOUT }),
    setupLogoutTimeout() {
      const minutesForTimeout = +this.$store.getters.analystTimeout || 15;
      const timeoutForLogout = minutesForTimeout * 60 * 1000;

      this.logoutInterval = setTimeout(async () => {
        const userName = localStorage.getItem(USER_NAME_KEY);
        const messageForLogout = `Log out after ${timeoutForLogout / 60000} minutes of inactivity.`;
        await this.logout({
          token: '', 
          isForce: false, 
          messageForLogout,
        });
        alert(`Sorry ${userName}. ${messageForLogout}`);
      }, timeoutForLogout);
    },

    clearLogoutTimeout() {
      if (this.logoutInterval) {
        clearTimeout(this.logoutInterval);
        this.logoutInterval = null;
      }
    },

    refreshLogoutTimeout() {
      if (this.$store.getters.userRole === rolesObject.ANALYST) {
        this.clearLogoutTimeout();
        this.setupLogoutTimeout();
      }
    },

    mousemove() {
      if (this.isAuthenticated) {
        this.debouncedRefresh();
      }
    },

    debouncedRefresh: debounce( function ()  {
      this.refreshLogoutTimeout();
    }, 500),

    handleCloseModal() {
      this.displayModal = false;
      localStorage.clear();
      sessionStorage.clear();
      location.assign('/login');
    },
  },

  created () {    
    const userToken = sessionStorage.getItem(USER_TOKEN_KEY);
    if(!userToken && this.$route.path !== '/') {
      this.logout({emptyToken: true});
      return;
    }
    window.addEventListener('storage', (e) => {  

      if (e.key === 'logoutUsers' && e.newValue) {
        this.displayModal = true;
        return;        
      }
    });
  },
};
</script>
